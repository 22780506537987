import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthGuard } from './AuthGuard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedService } from './Utilities/shared.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
   suppressScrollX: true
};

import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MaterialModule } from './material.module';
import { PageNotFoundComponent } from './PageNotFound/PageNotFound.component';
import { HttpClientModule } from '@angular/common/http';
import { LoaderInterceptor } from './Interceptors/loader.interceptor';
import { MatRadioModule } from '@angular/material/radio';
import { TokenInterceptor } from './Interceptors/token.interceptor';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { HelperService } from './Services/helper.service';
import { LogoutComponent } from './logout/logout.component';
import 'hammerjs';

import { MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, protectedResources, loginRequest, msalMSConfig } from './auth-config';
import { ProcessAssesmentService } from './Services/ProcessAssesment.service';
import { AuthService } from './Services/Auth.service';



/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {

   console.log('app module isLocal', localStorage.getItem('isLocal'));

   const queryReturnIsAuth = GetParam('isAuthenticated');
   const queryReturnIsLocal = GetParam('isLocal');

   if (queryReturnIsAuth != 0) {

      localStorage.setItem('isLocal', queryReturnIsLocal.toString());

      if (queryReturnIsLocal == 'False') {
         return new PublicClientApplication(msalMSConfig);
      } else {
         return new PublicClientApplication(msalConfig);
      }
   } else {
      if (localStorage.getItem('isLocal') != undefined) {
         console.log(localStorage.getItem('isLocal'));

         if (localStorage.getItem('isLocal') == 'False') {
            return new PublicClientApplication(msalMSConfig);
         } else {
            return new PublicClientApplication(msalConfig);
         }
      } else {
         return new PublicClientApplication(msalConfig);
      }
   }
}

function GetParam(name: any) {
   const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
   if (!results) {
      return 0;
   }
   return results[1] || 0;
}

/**
* MSAL Angular will automatically retrieve tokens for resources
* added to protectedResourceMap. For more info, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
   const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

   protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
      {
         httpMethod: 'GET',
         scopes: [...protectedResources.apiTodoList.scopes.read]
      },
      {
         httpMethod: 'POST',
         scopes: [...protectedResources.apiTodoList.scopes.write]
      },
      {
         httpMethod: 'PUT',
         scopes: [...protectedResources.apiTodoList.scopes.write]
      },
      {
         httpMethod: 'DELETE',
         scopes: [...protectedResources.apiTodoList.scopes.write]
      }
   ]);

   return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
   };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
   return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
   };
}

@NgModule({
   declarations: [
      AppComponent,
      LogoutComponent,
   ],
   imports: [
      BrowserModule,
      MaterialModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      FormlyModule.forRoot(),
      FormlyMaterialModule,
      HttpClientModule,
      PerfectScrollbarModule,
      CommonModule,
      MsalModule,
      TooltipModule,
      ToastrModule.forRoot(),
      ChartsModule,
   ],
   providers: [
      AuthGuard,
      SharedService,
      HelperService,
      CookieService,
      {
         provide: HTTP_INTERCEPTORS,
         useClass: MsalInterceptor,
         multi: true
      },
      {
         provide: MSAL_INSTANCE,
         useFactory: MSALInstanceFactory
      },
      {
         provide: MSAL_GUARD_CONFIG,
         useFactory: MSALGuardConfigFactory
      },
      {
         provide: MSAL_INTERCEPTOR_CONFIG,
         useFactory: MSALInterceptorConfigFactory
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService,
      ProcessAssesmentService,
      AuthService,
      {
         provide: PERFECT_SCROLLBAR_CONFIG,
         useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      // { provide: OAuthStorage, useValue: localStorage }
   ],
   bootstrap: [
      AppComponent,
   ]
})
export class AppModule { }
