


<div class="loginMain">
  <div class="row">
    <div class="col-md-4 col-sm-4 col-xs-12 loginLeft text-center">
      <a href=""><img src="../../../assets/Images/openbot-discovery-logo.png" /></a>
      <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: white;"> OpenBots <br>
        <hr style="width: 160px; color: #ffffff !important; background-color: red;"> Discovery</h3>
    </div>
    <div class="col-md-8 col-sm-8 col-xs-12 mainInner">
      <div class="loginInnerPadding text-center">
        <h1>Page not Found</h1>
        <span>404</span>
      </div>
    </div>
  </div>
</div>



<!-- <div class="loginMainPnl loginToolPnl">
  <div class="container">
    <div class="row">
      <div class="pagesPadding" class="mainContent mainTopPadding mainTopDash">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="loginGreyPnl mainInner text-center notFound">
              <h1>Page not Found</h1>
              <span>404</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div> -->