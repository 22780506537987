export const environment = {
  production: false,
  API_URL: 'https://discovery-api.openbots-dev.io/',
  AUTH_ISSUER: 'https://cloud.openbots-dev.io', //'https://localhost:44386', // 
  AUTH_REDIRECT_URI: 'https://discovery.openbots-dev.io',
  AUTH_POST_LOGOUT_URI: 'https://discovery.openbots-dev.io',

  // AUTH_REDIRECT_URI: 'http://localhost:4100',
  // AUTH_POST_LOGOUT_URI: 'http://localhost:4100',

  Change_Password_URI: "https://cloud.openbots-dev.io/members/changepassword",
  User_Profile_URI: "https://cloud.openbots-dev.io/members/userprofile",
  Openbots_Dashboard: "https://cloud.openbots-dev.io/members/dashboard",
  //API_URL: 'https://localhost:44383/',
  VERSION_URL: 'api/v1/',
  hub: 'notification',
  App_Insight_Key: 'f522484b-1e89-4019-8bff-a7bce6cc4fb7',
  isDebug: true,
  Azure_AD_B2C: {
    ClientId: '606b41bd-d818-4d98-937c-936312e6bd50',
    Instance: "https://authenticate.openbots-dev.io/",
    GalaxyLoginUrl: "https://cloud.openbots-dev.io/Login",
    Domain: "linkgalaxydevtoazure.onmicrosoft.com",
    TenantId: "bf6dbb13-06da-40c1-b388-faf1922152d7",
    SignUpSignInPolicyId: "B2C_1_SignUpSignInDev",
    EditProfilePolicyId: "B2C_1_EditProfileDev",
    MSSignUpSignInPolicyId: "B2C_1A_signup_signin",
    MSCallbackPath: "/signin-ms-oidc",
    MSSignedOutCallbackPath: "/signout-ms-callback-oidc",
    ResetPasswordPolicyId: "B2C_1_PasswordResetDev",
    ROPCPolicyId: "B2C_1_ROPC_AuthDev",
    GalaxyClientId: "2d7f9beb-a870-4f6c-9fea-363dec17c417",
    ClientSecret: "K6g8Q~xjF.PJhpVFd75ks3Ii9m~YV7no-SQUcddi",
    CallbackPath: "/signin-oidc",
    SignedOutCallbackPath: "/signout-callback-oidc",
    Scope: ['openid', 'email', 'profile', 'https://linkgalaxydevtoazure.onmicrosoft.com/adb2c/task.write', 'offline_access']
  }
};