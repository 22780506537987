import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../Utilities/config';
import { SharedService } from '../Utilities/shared.service';
import { throwError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserModel } from '../Models/UserModel';
import { ResetForgotPasswordModel } from '../Models/ResetForgotPasswordModel';
import { LoggedInUserInfo } from '../Models/Interfaces/LoggedInUserInfo';
import { Tokens } from '../Models/tokens'
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

@Injectable()
export class AuthService {

  constructor(private shareService: SharedService, private http: HttpClient,
    private sharedata: SharedService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
  ) {

    this.storedToken = localStorage.getItem('accessToken');
  }

  headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
  Authheaders = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") });
  encryptToken: string;
  randomPosition: number;
  randomStringLength: number;
  randomString: string;
  storedToken: string;
  decryptedToken: string;
  tokenData: any = null;
  private loggedUser: string;

  readonly rootUrl = Config.APIUrl;
  readonly versionUrl = Config.VERSIONURL


  RequestLogin(username: string, password: string) {
    const formData = "username=" + username + "&password=" + password;


    const headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + 'Auth/token', formData, { headers: headerData });
  }

  ForgotPassword(email, company) {


    const formData = { 'email': email, "company": company };
    const headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + 'Auth/ForgotPassword', formData, { headers: headerData });
  }

  RequestResetForgotPassword(resetForgotPassword: ResetForgotPasswordModel) {

    var formData = {
      newPassword: resetForgotPassword.newPassword,
      confirmPassword: resetForgotPassword.confirmPassword,
      oldPassword: resetForgotPassword.oldPassword
    }

    let headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(this.rootUrl + 'Auth/ChangePassword', formData,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('accessToken'), 'Content-Type': 'application/json' }) });
  }


  Register(user: UserModel) {
    return this.http.post(this.rootUrl + 'Auth/Register', user)
      .pipe(catchError((error) => { return throwError(error); })) as any;
  }

  TokenManagement(token: string) {

    this.encryptToken = btoa(token);


    this.randomPosition = 4;
    // this.random_position = Math.floor(Math.random() * 50) + 3;


    this.randomStringLength = 5;
    // this.random_string_length = Math.floor(Math.random() * 50) + 10;


    this.randomString = 'abcd';

    // random string
    // this.random_string = this.config.generateRandonString(this.random_string_length);

    this.encryptToken = this.encryptToken.slice(0, this.randomPosition) + this.randomString +
      this.encryptToken.slice(this.randomPosition);

    localStorage.setItem('accessToken', this.encryptToken);
    const tokenData = {
      random_string: this.randomString,
      random_position: this.randomPosition,
      actual_token: token
    };
    this.sharedata.tokenDataSource.next(tokenData);
    // this.sharedata.saveData(tokenData);
  }

  GetTokenWithUserInfo(Email: string, Password: string): Observable<LoggedInUserInfo> {
    var loginCredentials = {
      email: Email,
      password: Password

    }
    return this.http.post<LoggedInUserInfo>(this.rootUrl + 'Auth/token', loginCredentials, { headers: this.headerData })

  }

  CheckVersionNumber(): Observable<any> {
    return this.http.get(this.versionUrl + 'Application/AutomationNavigator/Version');
  }

  refreshToken() {
    var tokens = {
      token: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken')
    }
    return this.http.post<any>(this.rootUrl + 'Auth/Refresh', tokens, {
      headers: this.headerData
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }, error => {
      // console.log('Refresh token error', error);

      this.shareService.hide();
    }));
  }



  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }


  getJwtToken() {
    return localStorage.getItem('accessToken');
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('accessToken', jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem('accessToken', tokens.jwt);
    localStorage.setItem('refreshToken', tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

}
