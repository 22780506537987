import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../Services/Auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
// import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc'
import { Router } from '@angular/router';
import { SharedService } from '../Utilities/shared.service';
import { ToastrService } from 'ngx-toastr';
// import { authConfig } from '../oidc-config';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  toaster: boolean = true;

  constructor(public authService: AuthService, private route: Router,
    public sharedService: SharedService, private toastr: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addToken(request, localStorage.getItem('accessToken'));

    return next.handle(request).pipe(catchError(error => {
      this.sharedService.hide()

      if (this.sharedService.requestCounter > 0) {
        this.sharedService.requestCounter--;
      }

      if (error instanceof HttpErrorResponse && error.status !== 401) {
        if (error.error.errorObject) {
          this.toastr.error(`${error.error.errorObject.ServiceErrors[0]}`);
        } else {
          this.toastr.error(`${error.error.serviceErrors[0]}`);
        }
        return throwError(error);

      } else if (error instanceof HttpErrorResponse && error.status == 401) {
        this.authService.login();

      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  configureSignOn() {
    // this.oauthService.configure(authConfig);
    //old code start
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    //old code end
    // this.oauthService.loadDiscoveryDocumentAndLogin().then((_) => {
    //Login Successful
    // });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    //if isRefreshing is false, then start the refresh process
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      //refresh the token with http request 
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          localStorage.setItem('accessToken', token.jwt);
          localStorage.setItem('refreshToken', token.refreshToken);
          this.isRefreshing = false;
          //makes the subject not null which allowws the else statement to execute
          this.refreshTokenSubject.next(token.jwt);
          //re-executes the request that first initialized this method
          return next.handle(this.addToken(request, token.jwt));
        }));
      //else release each of the requests
    } else {
      return this.refreshTokenSubject.pipe(
        //holds all subsequent requests until subject is no longer null
        filter(token => token != null),
        //releases all requests one at a time
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private ShowSetPasswordError(error) {

    if (error.status == 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('ActiveOrganizationID');
      localStorage.setItem('IsOrganizationChanged', 'false');
      this.toastr.error('Please Login Again ');
      // this.oauthService.logOut(false);

    }
    else if (error.status == 500) {
      this.toastr.error('Something went wrong!');
    }
    else if (error.error.ServiceErrors != undefined || error.error.ServiceErrors != null) {

      if (error.error.ServiceErrors.length > 0) {

        this.toastr.error('Something went wrong!');
      }
    }
    else {
      this.toastr.error('Something went wrong!');
    }
  }
}
