import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './PageNotFound/PageNotFound.component';
import { AuthGuard } from './AuthGuard/auth.guard';
import { AppComponent } from './app.component';
import { LogoutComponent } from './logout/logout.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [

  {
    path: '', component: AppComponent, canActivate: [
      MsalGuard
    ],
  },
  {
    path: 'navigator', loadChildren: () => import('./Navigator/Navigator.module').then(m => m.NavigatorModule),
    canActivate: [
      MsalGuard
    ],
  }, // Navigator Module
  // AN diagram Tool
  {
    path: 'diagram', loadChildren: () => import('./anDiagramTool/anDiagramTool.module').then(m => m.AnDiagramToolModule),
    canActivate: [
      MsalGuard
    ],
  }, // Diagram Tool Module
  // { path: '**', component: PageNotFoundComponent },
  { path: 'logout', component: LogoutComponent },
  {
    // Needed for handling redirect after login
    path: 'signin-oidc',
    component: MsalRedirectComponent
  },
  {
    // Needed for handling redirect after login
    path: 'signin-ms-oidc',
    component: MsalRedirectComponent
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      useHash: true,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    }),
  ],
  bootstrap: [AppComponent],
  declarations: [
    PageNotFoundComponent,
  ],
  exports: [
    RouterModule,
    PageNotFoundComponent

  ]
})
export class AppRoutingModule { }
