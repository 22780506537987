import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageIcon',
  pure: true
})
export class ImageIconPipe implements PipeTransform {

  transform(name: string): string {
    if (name == null || name == undefined) return;
    else {
      if (name.includes('.pdf'))
        return 'filePdf-icon.png';
      else if (name.includes('.vsdx') || name.includes('.vsd'))
        return 'flow-icon.png';
      else if (name.includes('.docx') || name.includes('.doc') || name.includes('.docm'))
        return 'word-icon.png';
      else if (name.includes('.ppt') || name.includes('.pptx') || name.includes('.ppsx') || name.includes('.pptm'))
        return 'ppt-icon.png';
      else if (name.includes('.xlsx') || name.includes('.xlsm') || name.includes('.xlsb') || name.includes('.xltx'))
        return 'excel-icon.png';
      else
        return 'filedoc-icon.png';
    }
  }

}
