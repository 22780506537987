import { environment } from '../../environments/environment';

export const Config = {
  APIUrl: environment.API_URL + environment.VERSION_URL,
  VERSIONURL: environment.API_URL,
  AppInsightKey: environment.App_Insight_Key,
  debug: environment.isDebug,
}

export const ErrorCriteriaMessages = {
  LOWER_CASE_CRITERIA_MESSAGE: 'contains at least one lower case character',
  UPPER_CASE_CRITERIA_MESSAGE: 'contains at least one upper case character',
  DIGITS_CRITERIA_MESSAGE: 'contains at least one digit'
}

export const UploadOptions = {
  concurrency: 1,
  // maxUploads: 1,
  allowedContentTypes: ["*"]
}

export const AllContentTypes = [
  'image/jpeg', 'image/png', 'image/gif',
  'image/bmp', 'image/gif', 'image/svg+xml',
  'text/html', 'image/vnd.adobe.photoshop',
  'application/msword', 'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-excel', 'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint', 'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/pdf', 'text/plain', 'application/json',
  'application/x-rar-compressed', 'application/octet-stream',
  'application/zip', 'application/x-zip-compressed', 'multipart/x-zip'
];

export const DocumentContentTypes = [
  'application/msword', 'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
];

export const ExcelContentTypes = [
  'application/vnd.ms-excel', 'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
];

export const SlidesContentTypes = [
  'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint', 'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
];

export const SweetAlertOptions = {
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, Exit!'
}