import { Component, Inject, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
// import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
// import { authConfig } from '../oidc-config';

@Component({
  selector: 'an-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private cookieService: CookieService,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    const activeAccount =
      this.msalService.instance.getActiveAccount() ||
      this.msalService.instance.getAllAccounts()[0];

    // Extract login hint to use as logout hint

    const logoutHint = localStorage.getItem('accessToken');
    this.clearAppData();

    if (logoutHint == null) {
      window.location.href = `${environment.AUTH_ISSUER}/Logout`;
    } else {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.msalService.logoutPopup({
          account: activeAccount,
        });
      } else {
        this.msalService.instance.initialize().then(() => {
          this.msalService.logoutRedirect({
            account: activeAccount,
            idTokenHint: logoutHint,
            postLogoutRedirectUri: `${environment.AUTH_ISSUER}/Logout`,
          });
        })
      }
    }
  }


  private clearAppData(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.deleteAll();
    // this.authservice.configure(authConfig);

    // this.authservice.logOut(false);

  }
}
