import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Organization, AccessRequest } from '../Models/Interfaces/Organization';
import { catchError } from 'rxjs/operators';
import { Department } from '../Models/Interfaces/Department';
import { InvitedTeamMember } from '../Models/Interfaces/Team';
import { LoggedInUserInfo } from '../Models/Interfaces/LoggedInUserInfo';
import { ToastrService } from 'ngx-toastr';
import { Config } from "../Utilities/config";
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as joint from '@clientio/rappid';

@Injectable()
export class ProcessAssesmentService {
  headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
  Authheaders = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") });
  storedToken: string;
  org = false;
  decryptedToken: string;
  navbarStatus: boolean; // for applying CSS to main body
  inviteUserCss: boolean // for applying css to invite user
  randomString: string;
  tokenData: any = null;
  SelectedOrganization: Organization;
  AccessRequest: AccessRequest;
  UserInfo$ = new BehaviorSubject<LoggedInUserInfo>(null);
  CreateOrganization$ = new BehaviorSubject<LoggedInUserInfo>(null);
  UserInfo: LoggedInUserInfo;
  invitedTeamMember: InvitedTeamMember;
  PersonShortName: string;
  ActivePageTitle = "Process Catalog";
  processSearchForm: UntypedFormGroup;
  // https://dev-api-automationnavigator.azurewebsites.net/
  readonly rootUrl = Config.APIUrl;
  // readonly rootUrl = 'http://dev-api.automationnavigator.com/api/v1/';

  constructor(private http: HttpClient, private toastr: ToastrService,
    private fb: UntypedFormBuilder) {
    this.storedToken = localStorage.getItem('accessToken');

    this.processSearchForm = this.fb.group({
      searchInput: ['']
    })
  }
  getReactiveFormJason(): Observable<any> {
    return this.http.get('./assets/testForm.json');
  }


  // new dashboard filters logic with odata
  // filterProcesses(obj): Observable<any> {

  //     let processFilterQuery = this.makeProcessFilterQueryFromObject(obj);
  //     let processUrl = `${this.rootUrl}BusinessProcesses/ProcessWithResults/${localStorage.getItem('ActiveOrganizationID')}?$filter=${processFilterQuery}`;

  //   return this.http.get(processUrl);


  // }


  // query for dahboard filters
  makeProcessFilterQueryFromObject(obj) {
    let filterPriorites = [];
    let filterStatus = []
    let ownerArray = [];
    let selectedDepartments = [];

    let departmentsFilterString = '';
    let processFilterQuery = '';
    let priorityFilterString = '';
    let statusFilterString = '';
    let ownerFilterString = '';

    filterPriorites = obj.priority;
    filterStatus = obj.Status;
    ownerArray = obj.owner;
    selectedDepartments = obj.departments;



    //department filter
    if (selectedDepartments.length > 0) {
      for (const department of selectedDepartments) {
        // processFilterQuery+= `priority eq '${priority.name}' and `;
        departmentsFilterString += `OrganizationUnitId eq guid'${department.id}' or `;
      }
      departmentsFilterString = ` and ${departmentsFilterString}`;
    }

    if (departmentsFilterString.endsWith(' or '))
      departmentsFilterString = departmentsFilterString.substring(0, departmentsFilterString.length - 4);
    departmentsFilterString += '';


    //priority filter
    if (filterPriorites.length > 0) {
      for (const priority of filterPriorites) {
        // processFilterQuery+= `priority eq '${priority.name}' and `;
        priorityFilterString += `priority eq '${priority.name}' or `;
      }
      priorityFilterString = ` and ${priorityFilterString}`
    }

    if (priorityFilterString.endsWith(' or '))
      priorityFilterString = priorityFilterString.substring(0, priorityFilterString.length - 4);
    priorityFilterString += '';

    //status filter
    if (filterStatus.length > 0) {
      for (const status of filterStatus) {
        statusFilterString += `status eq '${status.name}' or `;
      }
      statusFilterString = ` and ${statusFilterString}`
    }

    if (statusFilterString.endsWith(' or '))
      statusFilterString = statusFilterString.substring(0, statusFilterString.length - 4);
    statusFilterString += ''
    // owner filter
    if (ownerArray.length > 0) {
      for (const owner of ownerArray) {
        ownerFilterString += `ownerName eq '${owner.name}' or `;
      }

      ownerFilterString = ` and ${ownerFilterString}`
    }


    if (ownerFilterString.endsWith(' or '))
      ownerFilterString = ownerFilterString.substring(0, ownerFilterString.length - 4);

    //concatenate priorit status and orner filter
    processFilterQuery = `${departmentsFilterString}${priorityFilterString}${statusFilterString}${ownerFilterString}`;

    if (processFilterQuery.endsWith(' and '))
      processFilterQuery = processFilterQuery.substring(0, processFilterQuery.length - 5);

    if (processFilterQuery.startsWith(' and '))
      processFilterQuery = processFilterQuery.substring(5, processFilterQuery.length);

    return processFilterQuery;
  }

  filterProcesses(obj): Observable<any> {

    // let ownerArray: any[] = obj.owner;

    // let ownerFilterUrl = '';
    // if (ownerArray.length > 0) {
    //   for (let index = 0; index < ownerArray.length; index++) {
    //     const element = ownerArray[index];
    //     if (ownerArray[index + 1] == null || ownerArray[index + 1] == undefined) {
    //       ownerFilterUrl += `'${element.name}'`
    //     } else {
    //       ownerFilterUrl += `'${element.name}' or ownerName eq`
    //     }
    //   }
    // } else {
    //   ownerFilterUrl = `''`;
    // }

    // Status IN (‘Not Started’ , ‘Inprogress’) AND Owner IN (‘Jazz’)

    // let newFilterUrl = `${this.rootUrl}BusinessProcesses/ProcessWithResults?$filter=priority IN ('Low', 'Medium')`;

    // let url = this.rootUrl + `BusinessProcesses/ProcessWithResults?$filter=status eq '${obj.status[0] != undefined ? obj.status[0].name : ''}' or status eq '${obj.status[1] != undefined ? obj.status[1].name : ''}' or status eq '${obj.status[2] != undefined ? obj.status[2].name : ''}' or status eq '${obj.status[3] != undefined ? obj.status[3].name : ''}'`;
    // url += ` or priority eq '${obj.priority[0] != undefined ? obj.priority[0].name : ''}' or priority eq '${obj.priority[1] != undefined ? obj.priority[1].name : ''}' or priority eq '${obj.priority[2] != undefined ? obj.priority[2].name : ''}' or ownerName eq ${ownerFilterUrl}`;

    // let url = `${this.rootUrl}BusinessProcesses/FilteredResults`;
    // return this.http.get(url);

    let filters = [
      {
        "organizationId": localStorage.getItem('ActiveOrganizationID'),
        "field": "Status",
        "filterValues": [],
        "isEmpty": true
      },
      {
        "organizationId": localStorage.getItem('ActiveOrganizationID'),
        "field": "Priority",
        "filterValues": [],
        "isEmpty": true
      },
      {
        "organizationId": localStorage.getItem('ActiveOrganizationID'),
        "field": "Owner",
        "filterValues": [],
        "isEmpty": true
      }
    ]

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.field == 'Status') {
        if (obj.status.length > 0) {
          obj.status.forEach(status => {
            filter.filterValues.push(status.name);
            filter.isEmpty = false;
          })
        } else {
          filter.isEmpty = true;

        }
      } else if (filter.field == 'Priority') {
        if (obj.priority.length > 0) {
          obj.priority.forEach(priority => {
            filter.filterValues.push(priority.name);
            filter.isEmpty = false;
          })
        } else {
          filter.isEmpty = true;
        }
      } else if (filter.field == 'Owner') {
        if (obj.owner.length > 0) {
          obj.owner.forEach(owner => {
            filter.filterValues.push(owner.name);
            filter.isEmpty = false;
          })
        } else {
          filter.isEmpty = true;
        }
      }
    }

    let filtersToPost = filters.filter(filt => !filt.isEmpty);
    let headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.rootUrl}BusinessProcesses/FilteredResults`, filtersToPost, { headers: headerData });
  }

  GetProcessHeatmap(organizationId: string): Observable<any> {
    ///api/v{version}/BusinessProcesses/GetProcessHeatmap/{organizationId}
    return this.http.get(this.rootUrl + 'BusinessProcesses/GetProcessHeatmap/' + organizationId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
  }

  getProcesses(): Observable<any> {

    return this.http.get(this.rootUrl + 'BusinessProcesses', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
  }

  getProcessesByOrgID(searchProcess: string): Observable<any> {

    if (searchProcess.length > 0) {
      return this.http.get(this.rootUrl + `BusinessProcesses/ProcessWithResults/${localStorage.getItem('ActiveOrganizationID')}?$filter=substringof(tolower('${searchProcess}'), tolower(Key)) or substringof(tolower('${searchProcess}'), tolower(Title))`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
    } else {
      return this.http.get(this.rootUrl + `BusinessProcesses/ProcessWithResults/${localStorage.getItem('ActiveOrganizationID')}`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
    }
  }

  getPaginatedProcessesByOrgID(searchProcess: string, filterObj, pageSize, skip: number, orderBy, orderDir,): Observable<any> {

    let processFilterQuery = this.makeProcessFilterQueryFromObject(filterObj);

    if (searchProcess.length > 0) {
      return this.http.get(this.rootUrl + `BusinessProcesses/ProcessWithResults/${localStorage.getItem('ActiveOrganizationID')}?$filter=substringof(tolower('${searchProcess}'), tolower(Key)) or substringof(tolower('${searchProcess}'), tolower(Title))&$orderby=${orderBy}+${orderDir}&$top=${pageSize}&$skip=${skip}`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
    } else if (processFilterQuery != '') {
      return this.http.get(this.rootUrl + `BusinessProcesses/ProcessWithResults/${localStorage.getItem('ActiveOrganizationID')}?$filter=${processFilterQuery}&$orderby=${orderBy}+${orderDir}&$top=${pageSize}&$skip=${skip}`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
    } else {
      return this.http.get(this.rootUrl + `BusinessProcesses/ProcessWithResults/${localStorage.getItem('ActiveOrganizationID')}?$orderby=${orderBy}+${orderDir}&$top=${pageSize}&$skip=${skip}`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });

    }
  }

  exportBusinessProcesses() {

    let fileExportUrl = `BusinessProcesses/ExportProcesses/${localStorage.getItem('ActiveOrganizationID')}`;
    let options = {};
    options = {
      responseType: 'blob',
      observe: 'response',
    };
    this.http.get<any>(`${this.rootUrl}` + fileExportUrl, options).subscribe((data: HttpResponse<Blob>) => {

      let dateTime = new Date();

      let convertedDateString = dateTime.toLocaleString();
      convertedDateString = convertedDateString.replace('at ', '');
      let locale = window.navigator.language;
      moment.locale(locale);
      let localDate = moment(convertedDateString).format('MMDDYYYY HHMMSSS');


      let fileName = `Process Report ${localDate}.xlsx`

      joint.util.downloadBlob(data.body, fileName)
    })


    // return this.http.get(this.rootUrl + , { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
  }

  // All Completed
  // GetCompaniesList() {

  //   return this.http.get(this.rootUrl + 'People/' + localStorage.getItem('personId') + '/Organizations', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  addOrganization(organization: Organization) {
    organization.isDeleted = false;
    organization.createdBy = this.UserInfo.userName;
    organization.deletedBy = this.UserInfo.userName;
    organization.members = [];

    organization.eMailDomain = organization.eMailDomain.replace('@', '');

    organization.members.push({
      "organizationId": "00000000-0000-0000-0000-000000000000",
      "personId": localStorage.getItem('personId'),
      "isAdministrator": true
    });

    let headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + 'Organizations', organization, { headers: headerData });
  }
  // GetDepartmentsOfOrganization(OrgId: string) {
  //   return this.http.get<DepartmentList>(this.rootUrl + 'Organizations/' + OrgId + '/OrganizationUnits', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  joinOrganization(organization: Organization) {
    organization['organizationId'] = organization.id;
    organization['personId'] = localStorage.getItem('personId');
    let headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + 'People/' + organization['personId'] + '/Organizations/' + organization.id, organization, { headers: headerData });
  }
  // getOrganization(Id) {
  //   return this.http.get<Organization>(this.rootUrl + 'Organizations/' + Id, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  getAllPersons() {
    return this.http.get<any>(this.rootUrl + 'People', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  getProcessOwners() {
    const orgID = localStorage.getItem('ActiveOrganizationID');
    return this.http.get<any>(this.rootUrl + `BusinessProcesses/${orgID}/ProcessOwners`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  getDocumentsByProcessID(deptID, processID): Observable<any> {
    return this.http.get(this.rootUrl + `BusinessProcesses/${processID}/ProcessDocuments`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
  }

  // getPersonsInOrganization() {

  //   return this.http.get<any>(this.rootUrl + `Organizations/${localStorage.getItem('ActiveOrganizationID')}/OrganizationMembers/People`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

  getOrganizationOData(name) {
    return this.http.get(this.rootUrl + `People/${localStorage.getItem('personId')}/Organizations/Search?startsWith=${name}&noLoader=true`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  getActiveOrganization(url) {
    return this.http.get<any>(this.rootUrl + url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  // exitOrganization(orgId) {
  //   return this.http.delete<any>(this.rootUrl + `People/${localStorage.getItem('personId')}/Organizations/${orgId}`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

  // deleteOrganization(orgId) {
  //   return this.http.delete<any>(this.rootUrl + `Organizations/${orgId}`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  getOrgSuggestions() {
    return this.http.get<any>(this.rootUrl + `People/${localStorage.getItem('personId')}/Organizations/Suggestions`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }
  // updateOrganization(Id, organization) {

  //   organization.deletedBy = this.UserInfo.email;
  //   organization.eMailDomain = organization.eMailDomain.replace('@', '');
  //   return this.http.put<Organization>(this.rootUrl + 'Organizations/' + Id, organization, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // DeleteDepartment(OrganizationID: string, DepartmentID: string): Observable<any> {
  //   return this.http.delete(this.rootUrl + 'Organizations/' + OrganizationID + '/OrganizationUnits/' + DepartmentID, { headers: this.headerData })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // AddDepartmentToOrganization(department: Department): Observable<any> {
  //   return this.http.post('Organizations/{organizationId}/OrganizationUnits', department, { headers: this.headerData })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // SaveDepartment(department: Department): Observable<any> {
  //   department.organizationId = localStorage.getItem('ActiveOrganizationID');
  //   return this.http.post(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/OrganizationUnits', department, { headers: this.headerData })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // UpdateDepartment(department: Department): Observable<any> {

  //   return this.http.put(this.rootUrl + 'Organizations/' + department.organizationId + '/OrganizationUnits/' + department.id, department, { headers: this.headerData })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  EditDepartment(department: Department): Observable<any> {

    return this.http.put(this.rootUrl + 'Organizations/' + department.organizationId + '/OrganizationUnits/' + department.id, department, { headers: this.headerData })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  //completed
  GetDepartmentByID(departmentID: string) {
    return this.http.get(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/OrganizationUnits/' + departmentID)
      .pipe(catchError((error) => { return throwError(error); }));
  }
  // GetPeopleinTeam() {
  //   var peopleInOrganization = this.http.get<TeamMemberList>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/OrganizationMembers/People', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  //   var pendingRequests = this.http.get<PendingRequestList>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/AccessRequests/Pending', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  //   return forkJoin([peopleInOrganization, pendingRequests]);
  // }
  // GetPeopleInOrganization() {
  //   return this.http.get<TeamMemberList>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/OrganizationMembers/People', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // GetPendingRequests() {
  //   return this.http.get<PendingRequestList>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/AccessRequests/Pending', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // ApproveAccessRequest(id: string) {
  //   let obj = {
  //     'organizationId': localStorage.getItem('ActiveOrganizationID'),
  //     'id': id
  //   }
  //   return this.http.put<PendingAccessRequest>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/AccessRequests/' + id + '/Approve', obj, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // RejectAccessRequest(id: string) {
  //   let obj = {
  //     'organizationId': localStorage.getItem('ActiveOrganizationID'),
  //     'id': id
  //   }
  //   return this.http.put<PendingAccessRequest>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/AccessRequests/' + id + '/Reject', obj, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  GetUserInfo(): Observable<LoggedInUserInfo> {
    return this.http.get<LoggedInUserInfo>(this.rootUrl + 'Auth/GetUserInfo')
      .pipe(catchError((error) => { return throwError(error); })) as any;
  }

  //completed
  GetCurrentUserInfo() {
    return this.http.get<LoggedInUserInfo>(this.rootUrl + 'People/' + localStorage.getItem('personId'), { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); })) as any;
  }

  // AddNewProcess(Process: ProcessModel) {
  //   return this.http.post(this.rootUrl + 'BusinessProcesses', Process, { headers: this.headerData })
  //     .pipe(catchError((error) => {
  //       return throwError(error);
  //     }));
  // }

  ThrowErrorNotification(error) {

    if (error.status == 401) {
      this.toastr.error('Access Denied');

    }
    else if (error.status == 500) {
      //this.toastr.error('Something went wrong!...');
    }
    else {
      if (error.ServiceErrors.length > 0) {
        this.toastr.error(error.ServiceErrors[0]);
      }
      else {
        //this.toastr.error('Something went wrong!...');
      }

    }

  }

  formatDate(date) {
    if (date == null) {
      return date
    }
    var convertedDateString = date.toLocaleString();
    convertedDateString = convertedDateString.replace('at ', '');
    var locale = window.navigator.language;
    moment.locale(locale);
    var localDate = moment(convertedDateString).format('l LT');

    return localDate;
  }

  SaveUserInfo(UserInfo: LoggedInUserInfo): Observable<any> {
    this.UserInfo.createdBy = this.UserInfo.name;
    this.UserInfo.deletedBy = this.UserInfo.name;
    return this.http.put(this.rootUrl + 'People/' + localStorage.getItem('personId'), UserInfo, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); })) as any;
  }

  AddPersonEmail(obj: any) {

    return this.http.post(this.rootUrl + 'People/' + localStorage.getItem('personId') + '/EmailVerifications', obj, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  GetUserEmails(): Observable<any> {
    return this.http.get<LoggedInUserInfo>(this.rootUrl + 'People/' + localStorage.getItem('personId') + '/EmailVerifications', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); })) as any;
  }

  InvUser(invitedTeamMember: InvitedTeamMember): Observable<any> {
    return this.http.post<InvitedTeamMember>(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/OrganizationMembers/InviteUser', invitedTeamMember, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); })) as any;
  }
  ComponentChanged(title: string, isOrgChange: string) {
    window.scrollTo(0, 0);
    localStorage.setItem('IsOrganizationChanged', isOrgChange);
    this.ActivePageTitle = title;
  }

  SetPassword(password: string) {

    var formData = {
      newPassword: password
    }

    return this.http.put(this.rootUrl + 'Auth/SetUserPassword', formData,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('accessToken'), 'Content-Type': 'application/json' }) });
  }

  SetForgotPassword(password: string, token, userID: string) {

    var formData = {
      newPassword: password,
      userId: userID,
      token: token
    }

    return this.http.put(this.rootUrl + 'Auth/SetPassword', formData,
      { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('accessToken'), 'Content-Type': 'application/json' }) });
  }

  resendVerifEmail(emailAddress: any) {

    return this.http.put(this.rootUrl + 'Auth/ResendEmailConfirmation/?emailAddress=' + emailAddress, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }
  DeleteUser(orgMemID: any) {

    return this.http.delete(this.rootUrl + 'Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/OrganizationMembers/' + orgMemID, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  DeleteProcess(ProcessId: any) {

    return this.http.delete(this.rootUrl + 'BusinessProcesses/' + ProcessId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  DuplicateProcess(ProcessId: any, processName) {
    const processNameObj = processName == '' ? {} : { processName: processName };
    return this.http.post(this.rootUrl + 'BusinessProcesses/' + ProcessId + '/DuplicateProcess', processNameObj, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  GetUserAgreement(): Observable<any> {
    return this.http.get<LoggedInUserInfo>(this.rootUrl + 'UserAgreement')
      .pipe(catchError((error) => { return throwError(error); })) as any;

  }
  PostUserAgreement(agreementObj: any): Observable<any> {
    return this.http.post(this.rootUrl + 'UserConsent', agreementObj)
      .pipe(catchError((error) => { return throwError(error); })) as any;

  }
  // DeleteUserFromOrg(personId, orgID) {
  //   return this.http.delete<any>(this.rootUrl + 'Organizations/' + orgID + '/OrganizationMembers/' + personId)
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }
  // UpdateProcess(ProcessID, Process: ProcessModel): Observable<any> {

  //   return this.http.put(this.rootUrl + 'BusinessProcesses/' + ProcessID, Process, { headers: this.headerData })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

  // GetProcess(ProcessID: number): Observable<any> {

  //   return this.http.get(this.rootUrl + 'BusinessProcesses/' + ProcessID, { headers: this.headerData })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

  uploadDocuments(pID: any, files) {

    const formData = new FormData();
    // formData.append('file', fileToUpload, fileToUpload.name);

    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    const token = localStorage.getItem('accessToken');
    return this.http.post(`${this.rootUrl}BusinessProcesses/${pID}/Documents?noLoader=true`, formData, {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }),
      reportProgress: true,
      observe: 'events'
    })

  }

  deleteDocument(id, processID) {
    return this.http.delete<any>(`${this.rootUrl}BusinessProcesses/${processID}/Documents/${id}`)
      .pipe(catchError((error) => { return throwError(error); }));
  }


  getProcessDocumentsSummary(pID: any): Observable<any> {

    return this.http.get(this.rootUrl + `BusinessProcesses/${pID}/DocumentsDetails`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
  }

  getProcessDetailsFromID(pID: any): Observable<any> {

    return this.http.get(this.rootUrl + `BusinessProcesses/ProcessWithResults?$filter=id eq guid'${pID}'`, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) });
  }

  getProcessDetails(ProcessID: string) {
    return this.http.get(this.rootUrl + 'BusinessProcesses/' + ProcessID, { headers: this.headerData })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  getAllApplications(pageSize, skip, orderBy, orderDir, filterQuery) {

    const orgID = localStorage.getItem('ActiveOrganizationID');
    let applicationsURl = '';
    if (filterQuery == '') {
      applicationsURl = `Organizations/${orgID}/GetAllApplications?$orderby=${orderBy}+${orderDir}&$top=${pageSize}&$skip=${skip}`;
    } else {
      applicationsURl = `Organizations/${orgID}/GetAllApplications?$filter=${filterQuery}&$orderby=${orderBy}+${orderDir}`;

    }


    return this.http.get<any>(this.rootUrl + applicationsURl, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));

  }

  updateProcessDetailsFromID(pID: any) {
    return this.http.put(this.rootUrl + 'BusinessProcesses/' + pID, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }

  GetLookupValues(LookupValues: string): Observable<any> {
    return this.http.get(this.rootUrl + 'LookupValues/' + LookupValues, { headers: this.headerData })
      .pipe(catchError((error) => { return throwError(error); }));
  }


  // PostCommnet(CommentObj: any): Observable<any> {
  //   return this.http.post(this.rootUrl + 'BusinessProcesses/' + CommentObj.businessProcessId + '/BusinessProcessesComments', CommentObj)
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

  GetComments(businessProcessId): Observable<any> {
    return this.http.get(this.rootUrl + 'BusinessProcesses/' + businessProcessId + '/BusinessProcessesComments/AllComments')
      .pipe(catchError((error) => { return throwError(error); }));
  }


  // RevokeAdmin(personId: string) {
  //   return this.http.put(this.rootUrl + 'People/' + personId + '/Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/RevokeAdmin', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

  // GrantAdmin(personId: string) {
  //   return this.http.put(this.rootUrl + 'People/' + personId + '/Organizations/' + localStorage.getItem('ActiveOrganizationID') + '/GrantAdmin', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }) })
  //     .pipe(catchError((error) => { return throwError(error); }));
  // }

}
