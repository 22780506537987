import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusColor',
  pure: true
})
export class StatusColorPipe implements PipeTransform {

  transform(value: string): string {
    if (value != null) {
      if (value == 'Not Started')
        return '#575757';
      else if (value == 'Completed')
        return '#11A425';
      else if (value == 'In Progress')
        return '#f18e3c';
      else if (value == 'Abandoned')
        return '#6263b3';
    }
    return;
  }


}
