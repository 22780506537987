import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ProcessAssesmentService } from '../Services/ProcessAssesment.service';

@Component({
  selector: 'app-PageNotFound',
  templateUrl: './PageNotFound.component.html',
  styleUrls: ['./PageNotFound.component.css']
})
export class PageNotFoundComponent implements OnInit {
  get_org = []
  constructor(private acroute: ActivatedRoute, private router: Router,
    public service: ProcessAssesmentService,
    private cookieService: CookieService,) {
    // this.router.navigate(['/url'], { queryParams:  youParam , skipLocationChange: true}); 
    this.acroute.queryParams.subscribe(params => {
      console.log(params.org)
      this.get_org = params.org;

      localStorage.setItem('ShareproceActiveOrganizationID', params.org);
      localStorage.setItem('ShareProcess_Flag', 'true');


    });

    // let email = this.acroute.snapshot['_routerState'].url
    // var n = email.match("/shareprocessemail");
    // let re = /\/shareprocessemail/gi;
    // let result = email.replace(re, "");
    // const final_res = result.split('?')
    // console.log(final_res)
    // const final_email = final_res[1].split('email=')
    // console.log(final_email[1])
    // localStorage.setItem('return_email', final_email[1])
    // console.log(localStorage.getItem('return_email') !== localStorage.getItem('UserEmail'))
    // if (localStorage.getItem('return_email') !== localStorage.getItem('UserEmail')) {
    //   this.logout()
    // }
    // if (n[0] == "/shareprocessemail") {
    //   if (this.cookieService.check('isUrl') == true) {
    //     console.log(this.cookieService.get('isUrl'))
    //     // let email = this.acroute.snapshot['_routerState'].url
    //     // var n = email.match("/shareprocessemail");
    //     // let re = /\/shareprocessemail/gi;
    //     // let result = email.replace(re, "");
    //     const dateNow = new Date();
    //     dateNow.setHours(dateNow.getHours() + 24);

    //     this.cookieService.set('isUrl', final_res[0], dateNow);
    //     localStorage.setItem('url', final_res[0])
    //     this.router.navigate([final_res[0]]);

    //   }
    //   else if (this.cookieService.check('isUrl') == false) {
    //     // alert('yes save')
    //     const dateNow = new Date();
    //     dateNow.setHours(dateNow.getHours() + 24);

    //     this.cookieService.set('isUrl', final_res[0], dateNow);
    //     localStorage.setItem('url', final_res[0])
    //     this.router.navigate([final_res[0]]);
    //   }
    //   else if (this.cookieService.get('isUrl') == undefined) {
    //     // alert('yes save')
    //     const dateNow = new Date();
    //     dateNow.setHours(dateNow.getHours() + 24);

    //     this.cookieService.set('isUrl', final_res[0], dateNow);
    //     localStorage.setItem('url', final_res[0])
    //     this.router.navigate([final_res[0]]);
    //   }

    // }
  }

  ngOnInit() {

  }

  logout() {
    this.service.UserInfo == null;
    localStorage.removeItem('url')
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('UserInfo');
    localStorage.removeItem('UserEmail');
    localStorage.removeItem('isPasswordSet');
    localStorage.removeItem('UserName');
    // localStorage.removeItem('return_email')
    localStorage.removeItem('OrganizationListLength');
    localStorage.removeItem('ActiveOrganizationID');
    // localStorage.removeItem('ShareProcess_Flag')
    // localStorage.removeItem('ShareproceActiveOrganizationID')
    localStorage.removeItem('ActiveOrganizationName');
    localStorage.removeItem('personId');
    localStorage.removeItem('isAdministrator');
    localStorage.removeItem('isUserConsentRequired');
    localStorage.removeItem('IsJoinOrgRequestPending');
    localStorage.setItem('IsOrganizationChanged', 'false');
    localStorage.removeItem('setFlag');
    this.cookieService.delete('isUrl')
    this.router.navigate(['/']);


  }

}
