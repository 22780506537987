import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortname'
})
export class ShortnamePipe implements PipeTransform {

  transform(fullName: string, args?: any): any {
    if (fullName) {
      let firstLetterString = fullName
        .split(" ")
        .map(n => n[0])
        .join("");
      let initials = firstLetterString.substr(0, 3);
      return initials.toUpperCase();
    }
  }

}
