import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2'
import { SweetAlertOptions, Config } from '../Utilities/config';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  /**
    * prints data in console.
    * 
    */
  log(data: any, message = 'print') {
    if (Config.debug) {
      console.log(message, data);
    }
  }

  /**
    * Open sweet alert.
    * 
    */
  sweetAlert(options?: any): Promise<SweetAlertResult> {
    let alertOptions = {};
    if (options) {
      alertOptions = options;
    } else {
      alertOptions = SweetAlertOptions
    }
    return Swal.fire(alertOptions);
  }

  /**
    * Get short name from full name  John Doe -> JD.
    * 
    */
  getShortName(fullName) {
    let firstLetterString = fullName
      .split(" ")
      .map(n => n[0])
      .join("");
    let initials = firstLetterString.substr(0, 3);
    return initials;
  }

  /**
  * TODO Preview File in browser
  * @parm data  this data is coming response from server
  * @returns void
  */
  viewFile(data: any): void {
    let blob = new Blob([data], { type: data.type });

    const nav = (window.navigator as any);
    if (nav && nav.msSaveOrOpenBlob) //IE & Edge
    {
      nav.msSaveOrOpenBlob(blob);
    } else {
      let anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.target = '_blank';
      anchor.click();
    }

  }

}
