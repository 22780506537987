import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { SharedService } from '../Utilities/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  toaster: boolean = true;
  // requestCounter = 0;
  constructor(private router: Router, public sharedService: SharedService, private toastr: ToastrService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const noLoader = req.url.split('&').pop();
    const noLoader2 = req.url.split('?').pop();

    this.sharedService.requestCounter++;

    req.url.replace('noLoader=true', '');

    if (noLoader !== 'noLoader=true' && noLoader2 !== 'noLoader=true') {
      this.sharedService.show();
    }

    return next.handle(req).pipe(
      finalize(
        () => {
          if (this.sharedService.requestCounter > 0) {
            this.sharedService.requestCounter--;
          }

          if (this.sharedService.requestCounter < 1) {
            this.sharedService.hide()
          }

        }));
  }
}
