import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, throwError } from 'rxjs';
import { LoggedInUserInfo } from '../Models/Interfaces/LoggedInUserInfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Config } from "../Utilities/config";
@Injectable()
export class SharedService {
  headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
  Authheaders = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") });
  readonly rootUrl = Config.APIUrl;
  tokenDataSource = new BehaviorSubject<any>(null);
  newProcessId = new BehaviorSubject<string>('');
  scrollSource = new BehaviorSubject<any>(null);
  searchProcess = '';
  refreshSearchProcess = new Subject<boolean>();
  isLoadingSource = new Subject<boolean>();
  isLoading = false;
  requestCounter = 0;
  isResultsLoadingSource = new Subject<boolean>();
  readonly versionUrl = Config.VERSIONURL;
  // data = this.dataSource.asObservable();

  constructor(private http: HttpClient, private toastr: ToastrService) { }


  show() {
    this.isLoadingSource.next(true);
  }
  hide() {
    this.isLoadingSource.next(false);
  }
  ThrowErrorNotification(error) {

    if (error.status == 401) {
      this.toastr.error('Access Denied');

    }
    else if (error.status == 500) {
      //this.toastr.error('Something went wrong!...');
    }
  }

  CheckVersionNumber(): Observable<any> {
    return this.http.get(this.versionUrl + 'Application/AutomationNavigator/Version');
  }


  getOAuthToken(code, returnUri): Observable<any> {
    return this.http.get(this.versionUrl + 'OAuth/token?code=' + code + "&returnUri=" + returnUri);
  }

}

