import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private Route: Router) {

  }
  canActivate(): boolean {

    if (localStorage.getItem('accessToken') != null || localStorage.getItem('accessToken') != undefined) {
      return true
    }//NOT TOKEN 
    else {
      // this.Route.navigate(['/login']);
      return false;
    }
  }
}
