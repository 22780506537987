<perfect-scrollbar style="max-width: 100%; height: 100vh;">
    <div *ngIf="isLoading" class="loading-indicator">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
    <div *ngIf="checking" class="loading-indicator">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
    <!-- <div *ngIf="isResultsLoading" class="results-loading">
        <h4>Result is calculating Please Wait...</h4>
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div> -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
</perfect-scrollbar>