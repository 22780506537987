import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
@Pipe({
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {

  //transform created time to seconds ago, x minutes ago and days ago 
  transform(dateUtc: string, args?: any): any {
    //convert it to local time
    let localTime = moment
      .utc(dateUtc)
      .local()
      .toLocaleString();

    //determine amount of time elapsed between created time and current time
    let nowTime = moment(localTime).fromNow();

    //determine if more than 24 hours has elapsed since the record was created
    let currentTime = moment()
      .local()
      .toLocaleString();
    let elapsedTime = moment(currentTime).diff(localTime, "hours");

    //if yes, concatenate the item.createdOn and the nowTime value
    if (elapsedTime >= 24) {
      var locale = window.navigator.language;
      moment.locale(locale);
      nowTime = `${moment(localTime).format("l LT")} (${nowTime})`;
    }
    return nowTime;
  }
}
