import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'businessDriver',
  pure: true
})
export class BusinessDriverPipe implements PipeTransform {

  transform(value: number): string {
    if (value == 0 || value == 3 || value == 6)
      return 'lightBlue';
    else if (value == 1 || value == 4)
      return 'lightRed';
    else if (value == 2 || value == 5)
      return 'lightGrey';
  }

}
